<div class="title-page flex align-items-center col-12 md:col-6 mb-0">
    <app-button-back></app-button-back>
    <span>Projetos Cadastrados</span>
</div>
<div class="container mt-2 p-5">
    <div class="p-fluid p-float-label col-12 md:col-4 mt-3">
        <p-dropdown class="textUppercase" [options]="clientsOptions" optionLabel="label" optionValue="value"
            [filter]="true" filterBy="label" filterPlaceholder="Digite para filtrar" [(ngModel)]="clientName"
            (onChange)="buscarProjectsClientByNameClient($event)">
        </p-dropdown>
        <label>* Clientes</label>
    </div>
    <div class="p-fluid p-float-label col-12 md:col-4 mt-3">
        <p-dropdown [options]="statusOptions" (onChange)="changeStatus($event.value)" class="textUppercase"
            [autoDisplayFirst]="false">
        </p-dropdown>
        <label>* Status</label>
    </div>
    <div class="grid mb-3 mt-2 ml-2">
        <p-button class="mr-2 mb-2" label="Cadastrar" icon="pi pi-plus" size="small"
            [routerLink]="'/app/projeto-cliente/cadastro'" *ngIf="isAdminOrGeranteProjetos()">
        </p-button>
        <p-button label="Pesquisa" icon="pi pi-trash" size="small" (click)="clearSearch()"></p-button>
    </div>

    <div class="card" *ngIf="projects?.length > 0   ; else emptyTable">
        <p-table [columns]="cols" [value]="returnedArray" styleClass="table-responsive"
            [tableStyle]="{'min-width': '60rem'}">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-projeto>
                <tr class="textUppercase">
                    <td>{{projeto.id}}</td>
                    <td>{{projeto.dataCadastro | date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{projeto.clienteNome}}</td>
                    <td>{{!projeto.status ? (projeto.dataAtualizacao | date: 'dd/MM/yyyy HH:mm') : 'EM ANDAMENTO'}}</td>
                    <td><strong>{{getProjectType(projeto.tipoProjeto)}}</strong></td>
                    <td>
                        <p-tag [value]="projeto.status ? 'ABERTO' : 'FINALIZADO'"
                            [severity]="projeto.status ? 'success' : 'danger'" [rounded]="true">
                        </p-tag>
                    </td>
                    <td *ngIf="isGeranteEstoque()">
                        <i class="pi pi-list icon-blue" (click)="viewProjectObservation(projeto)"></i>
                    </td>
                    <td class="flex justify-content-center align-items-center flex-column" style="height: 125.64px;"
                        *ngIf="!isGeranteEstoque()">
                        <i class="pi pi-user-edit mb-1 icon-edit" *ngIf="projeto.status"
                            (click)="detailProject(projeto.id)"></i>
                        <i *ngIf="!projeto.status" class="pi pi-eye mb-1 icon-detail"
                            (click)="detailProject(projeto.id)"></i>
                        <i class="pi pi-trash mb-1 icon-delete" *ngIf="isAdminOrGeranteSuporte() && projeto.status"
                            (click)="showDialogDelete(projeto)">
                        </i>
                        <i class="pi pi-check mb-1 icon-open" *ngIf="isAdmin() && !projeto.status"
                            (click)="showDialogOpen(projeto)"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="mt-3 p-datatable-striped">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
            [totalRecords]="projects?.length"></p-paginator>
    </div>

    <ng-template #emptyTable>
        <div class="my-2">
            <p-card class="w-full text-center" *ngIf="projects?.length == 0">
                Nenhum item encontrado
            </p-card>
        </div>
    </ng-template>
</div>

<div class="card flex justify-content-center" class="textUppercase">
    <p-dialog header="Observações do Projeto" [(visible)]="visible" [style]="{ width: '50rem' }"
        [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [modal]="true" [draggable]="false" [resizable]="false">
        <p><strong>Cliente: {{clientName | uppercase}}</strong></p>
        <div class="flex flex-column">
            <textarea rows="14" pInputTextarea [readonly]="true" [(ngModel)]="obsProjectClient" style="resize: none">
      </textarea>
        </div>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="visible = false" label="Fechar"></p-button>
        </ng-template>
    </p-dialog>
</div>

<div class="card flex justify-content-center" class="textUppercase">
    <p-dialog header="Header" [(visible)]="visibleDelete" [style]="{ width: '50vw' }" [modal]="true" [draggable]="false"
        [resizable]="false">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Deletar Projeto</span>
        </ng-template>
        <p>Projeto do Cliente: {{clientName}} será deletado. Deseja continuar?</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="visibleDelete = false" label="Sim" (click)="deleteProject()">
            </p-button>
            <p-button icon="pi pi-times" (click)="visibleDelete = false" label="Não"></p-button>
        </ng-template>
    </p-dialog>
</div>

<div class="card flex justify-content-center" class="textUppercase">
    <p-dialog header="Header" [(visible)]="visibleOpen" [style]="{ width: '50vw' }" [modal]="true" [draggable]="false"
        [resizable]="false">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Reabrir Projeto</span>
        </ng-template>
        <p>Projeto do Cliente: {{clientName}} será reaberto. Deseja continuar?</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="visibleOpen = false" label="Sim" (click)="openProject(project)">
            </p-button>
            <p-button icon="pi pi-times" (click)="visibleOpen = false" label="Não"></p-button>
        </ng-template>
    </p-dialog>
</div>