import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgxImageCompressService } from "ngx-image-compress";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./modules/layout/not-found/not-found.component";
import { AuthService } from "./services/auth.service";
import { ClientService } from "./services/client.service";
import { CoreService } from "./services/core.service";
import { GaleryService } from "./services/galery.service";
import { NotificationService } from "./services/notification.service";
import { UserService } from "./services/user.service";
import { ModalPdfModule } from "./shared/modal-pdf/modal-pdf.module";
import { ModalSendApproveComponent } from "./shared/modal-send-approve/modal-send-approve.component";
import { PipesModule } from "./shared/pipes/pipes.module";
import { LoadingInterceptor } from "./util/loading.interceptor";
import { StoreUsuario } from "./gerenciamento-estado/store/store-usuario";
import { StoreOs } from "./gerenciamento-estado/store/store-os";
import { ProjectModule } from "./pages/project/project.module";
import { ProjectService } from "./services/project.service";
import { ProjectClientRoutingModule } from "./pages/project-client/project-client-routing.module";
import { ButtonModule } from "primeng/button";
import { StorePedido } from "./gerenciamento-estado/store/store-pedido";
import { ReportsService } from "./services/reports.service";
import { NgApexchartsModule } from "ng-apexcharts";
import { GetAddressService } from "./services/getAddress.service";
import { DialogService } from "primeng/dynamicdialog";
import { SpinnerService } from "./services/spinner.service";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ModalNewInstallmentModule } from "./shared/modal-new-installment/modal-new-installment.module";

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        ModalSendApproveComponent,
        SpinnerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ConfirmDialogModule,
        ToastModule,
        RouterModule,
        ModalPdfModule,
        ModalNewInstallmentModule,
        NgApexchartsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PipesModule,
        MessagesModule,
        ButtonModule,
        MessageModule,
        TooltipModule,
        ProjectModule,
        ProjectClientRoutingModule,
    ],
    providers: [
        MessageService,
        { provide: LOCALE_ID, useValue: "pt-BR" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        ConfirmationService,
        CoreService,
        AuthService,
        GaleryService,
        GetAddressService,
        StoreUsuario,
        ReportsService,
        SpinnerService,
        StoreOs,
        StorePedido,
        ClientService,
        DialogService,
        UserService,
        ProjectService,
        NotificationService,
        NgxImageCompressService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
