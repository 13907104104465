import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./modules/layout/not-found/not-found.component";
import { LoggedGuard } from "./providers/guards/logged.guard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/autenticacao",
    },
    {
        path: "login",
        pathMatch: "full",
        redirectTo: "/autenticacao",
    },
    {
        path: "autenticacao",
        loadChildren: () =>
            import("./modules/authentication/authentication.module").then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: "app",
        canActivate: [LoggedGuard],
        loadChildren: () =>
            import("./pages/pages.module").then((m) => m.PagesModule),
    },
    {
        path: "404",
        component: NotFoundComponent,
    },
    {
        path: "**",
        redirectTo: "/404",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
