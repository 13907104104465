import { HostListener, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { StepEnum } from "../providers/Enums/etapa-Enum";

@Injectable({
    providedIn: 'root'
})

export class CoreService {

    private innerWidth: number = 0;
    private leftComponent = new Subject<boolean>();
    leftComponent$ = this.leftComponent.asObservable();

    constructor() {
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
    }

    get getInnerWidth() {
        return this.innerWidth;
    }

    parseCNPJ(value: string) {
        const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        if (x) {
            return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
        } else {
            return '';
        }
    }

    parseCPF(value: string): string {
        return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9)}`;
    }

    getStepColor(situcao: StepEnum): string {
        let etapaRetorno: string = '';
        switch (situcao) {
          case 1:
            etapaRetorno = "warning";
            break;
          case 2:
            etapaRetorno = "info";
            break;
          case 3:
            etapaRetorno = "success";
            break;
          case 4:
            etapaRetorno = "danger";
            break;
          default:
            etapaRetorno = "secondary";
        }
        return etapaRetorno;
      }
}
