<div class="mt-3">
  <h3 class="capitalize"><strong>Projetos do Vendedor: {{vendedorNome | lowercase}}</strong></h3>  
  <div class="flex flex-row justify-content-around flex-wrap mt-5">
    <div class="mb-4" *ngFor="let cliente of returnedArray">
      <p-card [header]="cliente.id + ' - ' + convertValues.convertNameCapitalize(cliente.nome | lowercase)" 
        [subheader]="'Cadastrado em ' + (cliente.dataCadastro | date:'dd/MM/yyyy')" [style]="{ width: '410px' }">      
        <ng-template pTemplate="footer">
          <h3>Etapa</h3>
          <p-badge [value]="getStep(cliente.etapa) | uppercase" [severity]="getStepColor(cliente.etapa)"></p-badge>
          <br><br>
          <p-button label="Detalhes Cliente" icon="pi pi-user" size="small" (click)="visualizarCliente(cliente.id)"></p-button>
        </ng-template>
      </p-card>
    </div>
  </div> 
  <div class="mt-3 p-datatable-striped">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="clientes?.length"></p-paginator>
  </div> 
  <div class="d-flex align-items-center justify-content-center" *ngIf="clientes?.length == 0">
    <h5><strong>Nenhum registro encontrato.</strong></h5>    
  </div>  
</div>